import React, { useEffect, useState } from "react"
import { graphql, PageProps } from "gatsby"
import getSearchParam from "@ecom/ui/utils/getSearchParam"

import Layout from "../../components/Layouts/mainPage"
import { NewHeader } from "../../components/Headers/NewHeader"
import { Banner } from "../../components/Banners/BlackFriday"
import { Banner as RoundedDynamicBanner } from "../../components/Banners/DynamicBanners/RoundedDynamicBanner"
import { HowInstallmentWorks } from "../../components/HowInstallmentWorks"
import { FormPKW } from "../../components/FormPKW"
import { NewCalculator } from "../../components/NewCalculator/NewCalculator"
import { Partners } from "../../components/PartnersNew"
import { Reviews } from "../../components/Reviews"
import { NewFooter } from "../../components/NewFooter"
import { MoreBenefitsRedisign } from "../../components/MorBenefitsRedisgn"
import { getPageData } from "../../helpers/getPageData"

import { BannerText, getDynamicBannerText } from "../../helpers/getDynamicBannerText"
import { getDynamicBannerUrl } from "../../helpers/getDynamicBannerUrl"

import { PageData } from "../../interfaces/pageProps"
import { FourAdvantagesPoints } from "../../components/FourAdvantagesPoints"
import { TopCta } from "../../components/TopCta"
import { blackFriday } from "../../components/PartnersNew/helpers"
import { InfoCards } from "../../components/InfoCardsHalva"
import { HALVA_BLACK_FRIDAY } from "../../components/InfoCardsHalva/helpers"
import { Questions } from "../../components/Questions"
import { useObserver } from "../../hooks/useObserver"

const titleForm = (
  <>
    Оформите карту сейчас – <br /> получите подарок!
  </>
)

const bannerTitle = `
24 месяца рассрочки <br /> <span>Черная пятница с&nbsp;Халвой</span>
`

const bannerDescription = `
  У всех партнеров <br/> с&nbsp;1 ноября по 8 декабря
`

const subTitlePartners =
  "Совершайте покупки в любых магазинах-партнерах и получайте 24 месяца рассрочки"

// Время для таймер(30мин)
const countDownTime = 1800000

// TODO: Пока Новую длинную заявку надо показывать только на тесте + там он еще пройдет через АБ тест
// пока поставлю переменную только, придется поменять на тип того - globalThis.KameleeonExperiment(потом)

export default function Page({ data }: PageProps<PageData>) {
  const { ligal, noIndex } = getPageData(data)
  const [dynamicBannerTextObj, setDynamicBannerTextObj] = useState<BannerText>()
  const [dynamicBannerImagesObj, setDynamicBannerImagesObj] = useState<any>()
  const isVisibleTopCta = useObserver(["topCta"])

  const bannerTextUrl = getSearchParam("h")
  const bannerImageUrl = getSearchParam("bn")

  useEffect(() => {
    if (bannerImageUrl) {
      const bannerImagesData = getDynamicBannerUrl(data.allDynamicBanner, bannerImageUrl)
      if (bannerImagesData !== null) {
        setDynamicBannerImagesObj(bannerImagesData)
      }
    }
  }, [data.allDynamicBanner, bannerImageUrl])

  useEffect(() => {
    if (bannerTextUrl) {
      getDynamicBannerText(bannerTextUrl).then((result) => {
        setDynamicBannerTextObj(result)
      })
    }
  }, [bannerTextUrl])

  // Получение первой страницы на которую зашел
  useEffect(() => {
    if (!sessionStorage.getItem("firstURL")) {
      sessionStorage.setItem("firstURL", document.URL)
    }
  }, [])

  return (
    <Layout noHeader noFooter noIndex={noIndex}>
      <NewHeader
        redLogoWithSovcombank
        showHint={false}
        hasCTA
        noRightSection={isVisibleTopCta}
        withButton={!isVisibleTopCta}
        isVisibleBlockTopCta={isVisibleTopCta}
      />
      <TopCta orderNum="1" countDownTime={countDownTime} />
      {dynamicBannerImagesObj && bannerImageUrl ? (
        <RoundedDynamicBanner
          large={dynamicBannerImagesObj.dymanicBannerDesk}
          mob={dynamicBannerImagesObj.dymanicBannerMob}
          title={dynamicBannerTextObj ? dynamicBannerTextObj.title : bannerTitle}
          description={dynamicBannerTextObj ? dynamicBannerTextObj.description : bannerDescription}
          orderNum="2"
        />
      ) : (
        <Banner
          description={dynamicBannerTextObj ? dynamicBannerTextObj.description : bannerDescription}
          title={dynamicBannerTextObj ? dynamicBannerTextObj.title : bannerTitle}
          orderNum="2"
        />
      )}
      <FourAdvantagesPoints
        title="Преимущества с Халвой:"
        withTitle
        indent
        variant="blackFridayHalva"
        orderNum="3"
      />
      <HowInstallmentWorks
        headTitle="Как получить рассрочку на&nbsp;24&nbsp;месяца"
        variant="blackFriday"
        orderNum="4"
      />
      <FormPKW
        progressBar
        dataLayerName="shortPersonalForm"
        title={titleForm}
        longTitle
        additionalEventInDataLayer
        countDownTime={countDownTime}
        withTimer
        formBtnText="Оформить карту"
        orderNum="5"
        onlyPKWPage
        isGreenApprove
      />
      <NewCalculator additionalEventInDataLayer orderNum="6" />
      <Partners
        title="Более 260&nbsp;000 магазинов-партнеров"
        subTitle={subTitlePartners}
        orderNum="7"
        additionalPartners={blackFriday}
      />
      <InfoCards title="" variant="autoPromo" cards={HALVA_BLACK_FRIDAY} orderNum="8" />
      <MoreBenefitsRedisign orderNum="9" bottomBlock={false} />
      <Reviews additionalEventInDataLayer orderNum="10" />
      <Questions orderNum="11" />
      <NewFooter ligal={ligal} orderNum="12" />
    </Layout>
  )
}
export const query = graphql`
  query {
    admin {
      page(url: "https://halvacard.ru/order/pkw/black-friday-halva/") {
        url
        ligal {
          text
        }
        notIndex
      }
    }
  }
`
